import React from 'react'
import why_csz from '../../../../dist/images/why_csg.png'

const WhyChoose = () => {
  return (
      <div className="container text-center why_choose" >
          <h2 className='mb-3'>Why Chuzeday for Your Small Business?</h2>
          <p>At Chuzeday, we empower Solo Business Owners with Smart, Seamless Software that streamlines operations, boosts profitability, and enhances customer service—helping YOU “Work Smart, Grow Local”, and enjoy greater freedom.</p>
          <div className="image-container" data-aos="zoom-in-up">
              <img src={why_csz} alt="Why Chuzeday for Your Small Business?" className="mx-auto" />
          </div>
      </div>
  )
}

export default WhyChoose
