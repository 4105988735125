import React, { useEffect, useState, useRef } from 'react';
import mobilescreen from '../../../../../dist/images/Services/app_download.png';
import Apk1 from '../../../../../dist/images/banner-slider/1.png';
import Apk2 from '../../../../../dist/images/banner-slider/2.png';
import { Link, useHistory } from "react-router-dom";
import GetIP from "../../../widgets/GetIP";
import { Button } from 'react-bootstrap';
import { PAGE_VIDEOS } from '../../../../../gql/Query';
import { useQuery } from '@apollo/client';

const DownloadApp = ({ idName }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [player, setPlayer] = useState(null);
    const history = useHistory();
    const [name, setName] = useState("");

    const userLocation = GetIP();
    const storeService = []

    const [video, setVideo] = useState({})
    const { data: page_videos, error: page_videos_error } = useQuery(PAGE_VIDEOS, {
        variables: {
            page: "home"
        }
    })

    useEffect(() => {
        if (page_videos) {
            setVideo(page_videos.page_videos)
        }
    }, [page_videos])

    // Initialize YouTube API
    useEffect(() => {
        // Load YouTube IFrame Player API
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // Create YouTube player when API is ready
        window.onYouTubeIframeAPIReady = () => {
            new window.YT.Player('youtube-player', {
                videoId: video?.video_code,
                events: {
                    onReady: (event) => {
                        setPlayer(event.target);
                    },
                }
            });
        };

        return () => {
            window.onYouTubeIframeAPIReady = null;
        };
    }, [video?.video_code]);

    const handlePlayPause = (e) => {
        e.preventDefault();
        if (player) {
            const playerState = player.getPlayerState();
            if (playerState === 1) { // If playing
                player.pauseVideo();
                setIsPlaying(false);
            } else { // If paused or not started
                player.playVideo();
                setIsPlaying(true);
            }
        }
    };

    return (
        <section id={idName} className='app_section' data-aos="fade-up">
            <div className="container py-4">
                <h2 className='d-flex align-items-center justify-content-center gap-3'>
                    See how Chuzeday works in 180 seconds.Just hit play
                    <a href='javascript:void(0)' onClick={handlePlayPause} className='cursor-pointer'>
                        <i className={`far ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
                    </a>
                </h2>
                <div className="row align-items-center g-4">
                    <div className="col-md-7">
                        <div className="position-relative">
                            <div className="video-container">
                                <iframe
                                    id="youtube-player"
                                    className="w-100"
                                    style={{ aspectRatio: '16/9' }}
                                    src={`https://www.youtube.com/embed/${video?.video_code}?enablejsapi=1&controls=1`}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 text-center">
                        <h3 className="mb-5">Download the guest app to book</h3>
                        <Link to="/system/book-a-demo">
                            <button className="btn btn-dark btn-lg mb-4 rounded-pill round_btn">
                                BOOK A DEMO
                            </button>
                        </Link>
                        <div className="d-flex justify-content-center gap-2 download-app">
                            <Link className="gplay" to={{ pathname: "https://play.google.com/store/apps/details?id=com.chuzeday.chuzeday" }} target="_blank">
                                <img src={Apk1} alt="app-icon" />
                            </Link>
                            <Link className="appstore" to={{ pathname: "https://apps.apple.com/us/app/chuzeday/id1626262034" }} target="_blank">
                                <img src={Apk2} alt="app-icon" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DownloadApp