import React from 'react'
import ebook from '../../../../../dist/images/ebook.png'
import james from '../../../../../dist/images/james.png'
import { Rating } from '@mui/material'
import EBookForm from './EBookForm'

const EBook = () => {
  return (
      <section className='ebook_section'>
          <div className="container py-5">
              <h2 className='text-center sec_heading mb-3'>
                  Free eBook Our Gift To You!
              </h2>
              <p className='sec_desc'>Lots of insightfull tips on how to make your business work for YOU</p>
              <div className="row align-items-center gx-5">
                  <div className="col-md-5 text-center">
                      <div className="position-relative ebook_img" data-aos="fade-right">
                          <img
                              src={ebook}
                              alt="ebook"
                              className="img-fluid mx-auto"
                          />
                      </div>
                  </div>
                  <div className="col-md-7">
                      <div className="position-relative" data-aos="fade-left">
                          <div className="mb-5">
                            <EBookForm/>
                          </div>
                        <div className="d-flex gap-4 align-items-start p-4 mb-5 review_card">
                            <div className="flex-shrink-0 reviewer">
                                <img
                                    src={james}
                                      alt="James"
                                    className="rounded-circle"
                                    width="80"
                                    height="80"
                                />
                            </div>

                            <div className="review_text">
                                <p className="fst-italic text-secondary mb-2">
                                    "This book is great,but the bonus materials are incredible.It change how i do business for the better"
                                </p>

                                <div className="d-flex gap-1 mb-2 rating_stars">
                                    <Rating
                                        value={5}
                                        name="half-rating"
                                        precision={0.1}
                                        readOnly
                                    />
                                </div>

                                <div>
                                    <strong className="d-block">- James</strong>
                                    <span className="text-secondary">(Business Owner)</span>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  )
}

export default EBook
