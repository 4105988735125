import React from 'react';
import { Rating } from '@mui/material';
import { Link } from 'react-router-dom';

const KeyFeature = () => {
    const features = [
        {
            subtitle: "Save time through automation",
            title: "24/7 On-line Booking",
            description: "Free up valuable time with automation and streamlined workflows",
            testimonial: {
                text: "I've been telling my clients to book me on Chuzeday, it feels like my own personal app. They love using it - and I love not having to manage my bookings.",
                author: "Nikki McGovern",
                company: "Massage and Pilates by Nikki",
                image: "/nikki.png",
                rating: 5
            },
            image: "/booking.png"
        },
        {
            subtitle: "Smart Business Tools",
            title: "Grow your Business",
            description: "Empower your business with a strong digital presence",
            testimonial: {
                text: "I've gained so many New Clients since joining Chuzeday. They found me on the Guest App and are now loyal regulars. It's a real game changer.",
                author: "Winnie Rath",
                company: "The Renee Studio Wexford",
                image: "/winnie.jpg",
                rating: 5
            },
            image: "/business.png"
        },
        {
            subtitle: "Streamline payments and boost cash flow",
            title: "Secure Integrated Payments",
            description: "Accept deposits or full payments at the time of booking, reducing no shows and cash flow issues.",
            testimonial: {
                text: "I love how I don't have to worry about my deposit being paid, I just let my clients do the work.",
                author: "Sinead O'Brien",
                company: "Shinelle Hair Studio Wexford",
                image: "/Sinead.jpg",
                rating: 5
            },
            image: "/payment.png"
        }
    ];

    return (
        <section className="features_section py-5">
            {features.map((feature, index) => (
                <div key={index} className="container mb-5">
                    <div className="row align-items-center gx-5 mt-5 pt-5">
                        {/* Left Content */}
                        <div className={`col-lg-6 ${index === 1 ? 'order-lg-2' : ''}`} data-aos="fade-left" >
                            <div className="feature_text">
                                <h6 className="mb-4">
                                    {feature.subtitle}
                                </h6>

                                <h2 className="display-4 fw-bold mb-4">
                                    {feature.title}
                                </h2>

                                <p className="text-secondary mb-4 short_desc">
                                    {feature.description}
                                </p>

                                {/* Testimonial Card */}
                                <div className="d-flex gap-4 align-items-start p-4 mb-5 review_card">
                                    <div className="flex-shrink-0 reviewer">
                                        <img
                                            src={feature.testimonial.image}
                                            alt={feature.testimonial.author}
                                            className="rounded-circle"
                                            width="80"
                                            height="80"
                                        />
                                    </div>

                                    <div className="review_text">
                                        <p className="fst-italic text-secondary mb-2">
                                            "{feature.testimonial.text}"
                                        </p>

                                        <div className="d-flex gap-1 mb-2 rating_stars">
                                            <Rating
                                                value={feature.testimonial.rating}
                                                name="half-rating"
                                                precision={0.1}
                                                readOnly
                                            />
                                        </div>

                                        <div>
                                            <strong className="d-block">- {feature.testimonial.author}</strong>
                                            <span className="text-secondary">({feature.testimonial.company})</span>
                                        </div>
                                    </div>
                                </div>

                                <Link to="/system/book-a-demo">
                                    <button className="btn btn-dark btn-lg mb-4 rounded-pill round_btn">
                                        Learn More
                                    </button>
                                </Link>
                            </div>
                        </div>

                        {/* Right Content - Feature Image */}
                        <div className={`col-lg-6 ftr_img_wrap ${index === 1 ? 'order-lg-1 ftr_img_1' : ''}`} data-aos="fade-right">
                            <div className="position-relative">
                                <img
                                    src={feature.image}
                                    alt={feature.title}
                                    className="img-fluid feature_img mx-auto"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </section>
    );
};

export default KeyFeature;