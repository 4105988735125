import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const EBookLeadForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        leadSource: 'Web'
    });
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsSubmitting(true);

        // Validation
        if (!formData.name.trim()) {
            setError('Name cannot be empty');
            setIsSubmitting(false);
            return;
        }
        if (!formData.email.trim() || !validateEmail(formData.email)) {
            setError('Please enter a valid email address');
            setIsSubmitting(false);
            return;
        }

        try {
            // Split name into first and last name for Zoho
            const [firstName = '', lastName = ''] = formData.name.split(' ');

            const response = await fetch('https://crm.zoho.eu/crm/WebToLeadForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    'First Name': firstName,
                    'Last Name': lastName,
                    'Email': formData.email,
                    'Phone': formData.phone,
                    'Lead Source': formData.leadSource,
                    'xnQsjsdp': '7d77b3b6e574ea998a160d0295f72c85c3f8b36f7723522e954240615783b249',
                    'xmIwtLD': 'f2548591605630c4a348f19ffd1b4ed204dc80893b525bab48207a31da4075b302adfd9311249d14b161edb95a073571',
                    'actionType': 'TGVhZHM=',
                    'returnURL': 'null'
                }).toString()
            });

            if (!response.ok) {
                throw new Error('Submission failed');
            }

            // Clear form after successful submission
            setFormData({
                name: '',
                email: '',
                phone: '',
                leadSource: 'Web'
            });

        } catch (err) {
            setError('Failed to submit form. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="container-fluid ebook_form p-4 rounded mb-4">
            {error && (
                <div className="alert alert-danger mb-3" role="alert">
                    {error}
                </div>
            )}

            <form onSubmit={handleSubmit} className="row g-3 align-items-center justify-content-center">
                <div className="col-12 col-md-3">
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Name"
                        className="form-control"
                        required
                    />
                </div>

                <div className="col-12 col-md-3">
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email"
                        className="form-control"
                        required
                    />
                </div>

                <div className="col-12 col-md-3">
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder="Phone"
                        className="form-control"
                    />
                </div>

                <div className="col-12 col-md-3">
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn w-100 d-flex align-items-center gap-2"
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#ebc11a';
                            e.currentTarget.style.color = '#1b7895';
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = 'transparent';
                            e.currentTarget.style.color = '#ebc11a';
                        }}
                    >
                        <i className='far fa-download'></i> {isSubmitting ? 'DOWNLOADING...' : 'DOWNLOAD'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EBookLeadForm;